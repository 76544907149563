/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import get from "lodash/get"

import { findBySlug } from "../helpers"
import Quote from "../components/design-partner/quote"
import BenefitsModule from "../components/benefits-module"
import BenefitsImagesModule from "../components/benefits-images-module"
import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectUnique from "../components/project-unique"
import TestimonialsModule from "../components/testimonials-module"
import { paramsCheck } from "../functions/functions"

const CustomDesign = ({ data }) => {
  paramsCheck()
  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-design-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (
      !heroBladePatternData ||
      !get(heroBladePatternData, "desktopImage.file.url")
    ) {
      return
    }

    const ctaList = [
      {
        text: heroBladePatternData.ctaTitle,
        type: "secondary",
        link: `/${heroBladePatternData.ctaDestination}`,
      },
    ]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        body={heroBladePatternData.description.description}
        title={heroBladePatternData.title}
        ctaList={ctaList}
        type="secondary"
      />
    )
  }
  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-design-benefits-module",
    })
    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-design-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderTestimonialsModule = () => {
    const testimonialsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-design-testimonials-module",
    })
    if (!testimonialsBladePatternData) return

    return (
      <TestimonialsModule bladePatternData={testimonialsBladePatternData} />
    )
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-design-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderBenefitsImageModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "custom-design-benefits-list-module",
    })

    if (!bladePatternData) return
    return (
      <BenefitsImagesModule basicCardList={bladePatternData.contentCards} />
    )
  }

  const renderQuote = () => {
    // const sectionList = findBySlug({
    //   list: data.contentfulPage.sectionList,
    //   slug: "quote-for-custom-design"
    // })
    // console.log("sectionList",sectionList.contentCards[0].topics)
    // const bladePatternData = findBySlug({
    //   list: data.contentfulPage.bladePatternList,
    //   slug: "custom-design-quote-module",
    // })

    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return

    const bladePatternData = data.allContentfulBladePattern.edges[0].node

    return (
      <div
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          py: theme => theme.spacing.vertical.lg,
        }}
      >
        {bladePatternData.quote.map(quote => (
          <Quote name={quote.name} quote={quote} photo={quote.image} />
        ))}
      </div>
    )
  }

  return (
    <Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderBenefitsModule()}
      {renderBenefitsImageModule()}
      {renderTestimonialsModule()}
      {renderCtaModule()}
      {renderServicesModule()}
      {renderQuote()}
    </Page>
  )
}

export default CustomDesign

export const query = graphql`
  query customDesignPageQuery {
    contentfulPage(slug: { eq: "custom_design" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulBladePattern(
      filter: { slug: { eq: "custom-design-quote-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
        }
      }
    }
  }
`
